@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.task-list { 
    @apply bg-black w-full py-2 cursor-pointer;
}

.review-content-section { 
    @apply border rounded-lg;
}

.review-table-container .headers { 
    @apply flex flex-nowrap justify-evenly overflow-hidden bg-gray-200 rounded-t-lg;
}

.col-header { 
    @apply truncate font-bold text-[12px] w-full p-1;
}

.table-data { 
    @apply flex flex-nowrap justify-evenly overflow-hidden p-1 cursor-pointer;
}

.col-data { 
    @apply truncate text-[12px] w-full
}

.table-data:hover { 
    @apply bg-gray-100;
}

.no-select { 
    @apply pointer-events-none cursor-move;
}

.selected-user { 
    @apply border-[#3DD598] ;
}

.non-selected-user { 
    @apply border-gray-300;
}

.admin-task-table-body tr td, .admin-task-table-head tr td { 
    @apply overflow-hidden truncate
}

.admin-task-id {
    @apply max-w-40 w-40
}

.admin-task-attachment { 
    @apply w-12
}

.admin-task-status, .admin-task-sortable, .admin-task-assignees, .admin-task-type { 
    @apply w-32
}

.admin-task-id-skeleton {
    @apply w-96
}

.admin-task-attachment-skeleton { 
    @apply w-96
}

.admin-task-status-skeleton, .admin-task-sortable, .admin-task-assignees-skeleton, .admin-task-type-skeleton { 
    @apply w-96
}
