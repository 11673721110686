@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.name { 
    @apply text-center mt-3 text-white text-xl font-bold
}

.role {
    @apply text-muted text-center mt-1
}

.nav-items { 
    @apply flex flex-col gap-2 mt-10 mx-3 text-muted
}

.nav-item { 
    @apply hover:bg-[#030705] rounded-lg p-1 py-2 flex items-center align-middle cursor-pointer;
}

.nav-item-icon { 
    @apply w-10 flex justify-center;
}

.active { 
    @apply bg-[#030705] text-white 
}

.active-border { 
    @apply w-0.5 bg-[#00c397]
} 

.sub-nav-item { 
    @apply bg-[#f1f3f1] border-b-2 border-[#f1f3f1] hover:underline decoration-[0.17rem] underline-offset-[0.5rem] rounded-lg p-1 py-2 flex items-center align-middle text-base cursor-pointer text-muted font-bold;
}

.sub-nav-active { 
    @apply underline underline-offset-[0.5rem] decoration-[#00c397] text-text not-italic text-lg;
}