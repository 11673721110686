@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Ensure container looks normal */
.textarea-container {
    border: 1px solid #ccc;
    border-radius: 20px;
    transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    @apply w-full m-0 p-0 flex items-center align-middle overflow-hidden bg-white
  }
  
  /* When textarea is focused, apply styles to parent */
  .textarea-container:has(textarea:focus) {
    border-color: #3b82f6; /* Tailwind Blue */
    box-shadow: 0 0 6px rgba(59, 130, 246, 0.5);
  }
  
  /* Remove textarea outline & focus styling */
  .textarea-container textarea {
    outline: none;
    border: none;
    background: transparent;
    @apply w-full m-0 
  }
  

  textarea:focus { 
    @apply: outline-none border-none shadow-none;
  }
  
  /* For WebKit-based browsers (Chrome, Safari, Edge) */
  textarea::-webkit-scrollbar {
    width: 6px; /* Make scrollbar thin */
    position: absolute;
    right: 0; /* Keep it inside */
  }
  
  textarea::-webkit-scrollbar-track {
    background: transparent; /* Hide track */
    border-radius: 10px; /* Round the edges */
  }
  
  textarea::-webkit-scrollbar-thumb {
    background: rgba(59, 130, 246, 0.7); /* Match Tailwind blue */
    border-radius: 10px; /* Rounded scrollbar */
    margin-right: 2px; /* Keeps scrollbar from overflowing */
  }
  
  textarea::-webkit-scrollbar-thumb:hover {
    background: rgba(59, 130, 246, 0.9); /* Slightly darker on hover */
  }
  
  /* For Firefox */
  textarea {
    scrollbar-width: thin;
    scrollbar-color: rgba(59, 130, 246, 0.7) transparent;
    padding-right: 10px; /* Ensures text doesn't get hidden behind scrollbar */
  }
  
  .highlighted { 
    @apply bg-gray-200
  }