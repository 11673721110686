.imessage {
    background-color: #fff;
    border: 1px solid #e5e5ea;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    font-family: "SanFrancisco";
    font-size: 1.25rem;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.5rem 1.5rem;
  }
  
  .imessage div {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
  }
  
  .imessage p::before,
  .imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }
  
  div.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff; 
  }
  
  div.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  div.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
  }
  
  p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }
  
  div.from-me ~ div.from-me {
    margin: 0.25rem 0 0;
  }
  
  div.from-me ~ div.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }
  
  div.from-me ~ div.from-me:last-child {
    margin-bottom: 0.5rem;
  }
  
  div.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }
  
  div.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  div.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }
  
  div[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }
  
  div[class^="from-"].emoji::before {
    content: none;
  }
  
  .no-tail::before {
    display: none;
  }
  
  .margin-b_none {
    margin-bottom: 0 !important;
  }
  
  .margin-b_one {
    margin-bottom: 1rem !important;
  }
  
  .margin-t_one {
    margin-top: 1rem !important;
  }  
  
  /* general styling */
  @font-face {
    font-family: "SanFrancisco";
    src:
      url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2") format("woff2"),
      url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff") format("woff");
  }
  

  .comment {
    color: #222;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    max-width: 100%;
    padding: 0;
  }
  
  @media screen and (max-width: 800px) {
    body {
      margin: 0 0.5rem;
    }
  
    .container {
      padding: 0.5rem;
    }
  
    .imessage {
      font-size: 1.05rem;
      margin: 0 auto 1rem;
      max-width: 600px;
      padding: 0.25rem 0.875rem;
    }
  
    .imessage p {
      margin: 0.5rem 0;
    }
  }

  .message-list:focus { 
    @apply: outline-none border-none shadow-none;
  }
  
  /* For WebKit-based browsers (Chrome, Safari, Edge) */
  .message-list::-webkit-scrollbar {
    width: 6px; /* Make scrollbar thin */
    position: absolute;
    right: 0; /* Keep it inside */
  }
  
  .message-list::-webkit-scrollbar-track {
    background: transparent; /* Hide track */
    border-radius: 10px; /* Round the edges */
  }
  
  .message-list::-webkit-scrollbar-thumb {
    background: rgba(59, 130, 246, 0.7); /* Match Tailwind blue */
    border-radius: 10px; /* Rounded scrollbar */
    margin-right: 2px; /* Keeps scrollbar from overflowing */
  }
  
  .message-list::-webkit-scrollbar-thumb:hover {
    background: rgba(59, 130, 246, 0.9); /* Slightly darker on hover */
  }
  
  /* For Firefox */
  .message-list {
    scrollbar-width: thin;
    scrollbar-color: rgba(59, 130, 246, 0.7) transparent;
    padding-right: 10px; /* Ensures text doesn't get hidden behind scrollbar */
  }
  