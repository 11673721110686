@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.card-overviews { 
    @apply flex gap-5 mt-5 flex-wrap justify-center
}

.overview-card { 
    @apply h-32 w-72 border overflow-hidden rounded-lg flex flex-col justify-between
}

.overview-card-info { 
    @apply flex items-center align-middle p-1 gap-2
}

.icon { 
    @apply bg-gray-200 text-gray-700 rounded-full p-2 w-8 h-8 flex items-center justify-center align-middle
}

.overview-card-info .value { 
    @apply font-bold flex gap-1 items-end
}

.overview-card-info .label { 
    @apply text-xs text-muted
}

.trend { 
    @apply text-xs flex gap-0.5
}

.card-graph-container { 
    @apply w-full h-[100px] overflow-hidden relative
}