@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inter:wght@100..900&family=Outfit:wght@100..900&display=swap');
/* 
* { 
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
} */

.title-font { 
  font-family: 'Anton', sans-serif;
}

.great-vibes-regular {
  font-family: "Great Vibes", serif;
  font-weight: 400;
  font-style: normal;
}

.outfit-font { 
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
}

.region-bullet { 
  background: #3dd598;
  @apply rounded-full p-2
}

.dark-mode-toggle-bullet { 
  background: rgba(246, 248, 250, 1);
  @apply rounded-full 
}

.dark-mode-toggle-bullet-dark { 
  background: rgba(70, 73, 84, 1)
}

.dark-mode-toggle-moon-bg { 
  background: rgba(53, 57, 69, 1);
}

.navbar-font-grey { 
  color: rgba(146, 146, 157, 1);
}

.navbar-bg-dark { 
  background: rgba(44, 47, 57, 1) !important;
}

.inter-font { 
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.inter-font-600 { 
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: bold;
}

.inter-font-bold { 
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
}

.inter-font-light { 
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: bold;
}

.btn {
  @apply py-2 px-4 font-semibold rounded-lg;
}
.btn-light {
  @apply bg-primary text-white;
}
.btn-dark {
  @apply bg-primary text-gray-300;
}

aside {
  width: 64px; /* Adjust as needed */
  height: 100%;
  position: fixed;
  /* Other styles... */
}

.card { 
  @apply bg-card dark:bg-card-dark rounded-lg shadow-lg;
}

h1, h2, h3, h4 { 
  @apply font-bold
}

.heading-1 { 
  @apply text-6xl mb-5
}

.heading-2 { 
  @apply text-4xl mb-5
}

.body { 
  @apply text-text dark:text-text-dark
}

.link { 
  @apply text-primary;
  cursor: pointer;
}

.input { 
  @apply p-3 bg-background dark:bg-background-dark hover:shadow-lg
}

.container { 
  @apply w-4/5
}

.h-screen-minus-header { 
  height: calc(100vh-74px)
}

.table { 
  @apply flex flex-col justify-between
}

.table-row { 
  @apply flex flex-row justify-between items-center
}

.table-row div { 
  @apply overflow-hidden h-[1.5rem] text-start
}

.secondary-nav { 
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: bold;
  font-size: 16px;
}

.main-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure navbar stays on top of other content */
}

body {
  padding-top: 66px
}

.test-config { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5; /* Adjust the opacity as needed */
    background-color: black; /* Replace with your desired background color */
}

.zindex-1 { 
  z-index: 1;
}

.text-2xs { 
  font-size: 8px; /* 12 px */; 
  line-height: .75rem;
}

.text-circle { 
  font-size: 5px;
}

.success-text { 
  @apply text-green-800
}

.success-bg { 
  @apply bg-green-100
}

/* BADGES */

.default-badge { 
  @apply bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300
}
.dark-badge { 
  @apply bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300
}
.red-badge { 
  @apply bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300
}
.green-badge { 
  @apply bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300
}
.yellow-badge { 
  @apply bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300
}
.indigo-badge { 
  @apply bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300
}
.purple-badge { 
  @apply bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300
}
.pink-badge { 
  @apply bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300
}


/* BUTTONS */

.green-button { 
  @apply text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2
}

.xs-button { 
  @apply px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
}

.light-button { 
  @apply inline-block rounded border-2 border-neutral-50 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-300 hover:text-neutral-200 focus:border-neutral-300 focus:text-neutral-200 focus:outline-none focus:ring-0 active:border-neutral-300 active:text-neutral-200 motion-reduce:transition-none dark:hover:bg-neutral-600 dark:focus:bg-neutral-600
}

.dark-button { 
  @apply rounded border-2 border-neutral-800 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-neutral-800 transition duration-150 ease-in-out hover:border-neutral-800 hover:bg-text-dark hover:text-neutral-800 focus:border-neutral-800 focus:bg-neutral-100 focus:text-neutral-800 focus:outline-none focus:ring-0 active:border-neutral-900 active:text-neutral-900 dark:border-text dark:hover:bg-text dark:text-text-dark dark:focus:bg-neutral-900
}

.primary-button { 
  @apply bg-primary px-2 py-1 rounded-md shadow-lg hover:bg-primary-dark hover:text-text text-text-dark
}

.primary-button-disabled { 
  @apply px-2 py-1 rounded-md shadow-lg bg-primary-dark text-muted
}

.accent-border { 
  border-color: #e5e7eb;
}

.text-inactive { 
  @apply text-gray-300;
}

.text-muted-light { 
  @apply text-gray-400;
}

/* BANNERS */

.success-banner { 
  @apply bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md
}

.info-banner {
  @apply bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md
}

.warning-banner { 
    @apply bg-yellow-100 border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md
}

.error-banner {
  @apply bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md
}

.remove-input-txt-border input[type='text']:focus {   box-shadow: none; }

@layer utilities {
  .scrollbar {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #3dd598 transparent; 
  }

  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    @apply bg-gray-200;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #3dd598;
    border-radius: 10px;
    border: 3px solid #3dd598;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

.notification-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #007bfff5; /* Bootstrap primary blue with 70% transparency */
  z-index: 1;
}

.hover\:rotate-icon:hover .fa-repeat {
  transform: rotate(180deg);
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75% { 
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shake {
  0%, 100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(-5deg);
  }
}
/* Hide scrollbar for all browsers */
.file-viewer::-webkit-scrollbar {
  display: none;
}
.file-viewer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.shake {
  animation: shake 0.2s ease-in-out infinite;
}

.clip-left {
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 100%, 0 100%);
}

.clip-right {
  clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Sprout Sheets Styling */
.selected, .group-cell-selection { 
  @apply border-blue-700 border
}

.selected:focus, .group-select:focus{ 
  @apply outline-none border
}

.col-selected, .row-selected { 
  @apply font-bold bg-blue-100
}

.group-select { 
  @apply bg-blue-100 
}

.group-select-left { 
  @apply border-l-blue-700
}

.group-select-right { 
  @apply border-r-blue-700
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
.blue-scrollbar::-webkit-scrollbar {
  width: 6px; /* Make scrollbar thin */
  position: absolute;
  right: 0; /* Keep it inside */
}

.blue-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Hide track */
  border-radius: 10px; /* Round the edges */
}

.blue-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(59, 130, 246, 0.7); /* Match Tailwind blue */
  border-radius: 10px; /* Rounded scrollbar */
  margin-right: 2px; /* Keeps scrollbar from overflowing */
}

.blue-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(59, 130, 246, 0.9); /* Slightly darker on hover */
}

/* For Firefox */
.blue-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(59, 130, 246, 0.7) transparent;
  padding-right: 10px; /* Ensures text doesn't get hidden behind scrollbar */
}

.reject-button {
  @apply text-red-600 border border-red-600 bg-red-100 
         hover:bg-red-600 hover:text-white 
         transition-all duration-200 ease-in-out;
}

.reject-button .reject-button-icon {
  @apply transition-all duration-200 ease-in-out;
}

.reject-button:hover .reject-button-icon {
  @apply bg-white text-red-600;
}

.approve-button {
  @apply text-green-600 border border-green-600 bg-green-100 
         hover:bg-green-600 hover:text-green-100 
         transition-all duration-200 ease-in-out;
}

.approve-button .approve-button-icon {
  @apply transition-all duration-200 ease-in-out;
}

.approve-button:hover .approve-button-icon {
  @apply bg-white text-green-600;
}

.approve-button-icon { 
  @apply bg-green-600 text-white;
}

/* Spinner Animation */
.loading-spinner svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.download-icon {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.arrow {
    transform-origin: center;
}

.download-icon:hover .arrow {
    animation: drop-reset 1.4s infinite;
}

@keyframes drop-reset {
    0% { transform: translateY(0); }       /* Start position */
    50% { transform: translateY(6px), ease-in-out; } /* Ease down */
    90% { transform: translateY(6px); }     /* Hold at the bottom briefly */
    100% { transform: translateY(0), steps(1); } /* Instantly reset */
}